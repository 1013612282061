<template>
  <v-container>
    <v-breadcrumbs :items="items"></v-breadcrumbs>
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="400">
      <v-card-title>My Account</v-card-title>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center">
          <div><v-avatar color="black" size="140"><span class="white--text text-h1">{{
                      auth.User.name.charAt(0).toUpperCase()
                    }}</span></v-avatar></div>
          <div class="text-h6">{{ auth.User.name }}</div>

          <div class="text-center pt-3" v-for="t in type" :key="t.id">
            <v-btn v-if="t.route" :to="{name:t.route}" block rounded outlined color="primary" dark>
              {{ t.value }}
            </v-btn>
            <v-btn v-else  block rounded outlined color="primary" dark>
              {{ t.value }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    type: [
      {
        id: 1,
        value: "Change Password",
        route: 'PageAccountChangePassword',
      },
      // {
      //   id: 2,
      //   value: "Change Email Address",
      //   route: "PageAccountChangeEmail",
      // },
      {
        id: 3,
        value: "Change Mobile Number",
        route: 'PageAccountChangeMobile',
      },
      {
        id: 4,
        value: "Log Out",
        route: "PageMainLogout",
      },
    ],
    items: [
      {
        text: "My Account",
        disabled: true,
        to: { name: "PageTestAccount" },
      },
    ],
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    clickMenu(item) {
      this.$router.push({ name: item.route });
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 300px !important;
}
</style>